import { Sort } from "./common";
import { FileFormatDetail, defaultFormatFile } from "types/admin/passport";

// Версия SMB
export type SmbVersion = "2.1" | "1.0";

// тип источника
export enum SourceType {
  SHARED_FOLDER = "sharedFolder",
  SPRUT_POSTGRES = "sprutPostgres",
  SPRUT_INTERBASE = "sprutInterbase",
  SPRUT_ORACLE = "sprutOracle",
  NPP_NTT_SPRUT_POSTGRES = "nppNttSprutPostgres",
  VIRTUS_POSTGRES = "virtusPostgres",
  PARAGRAF = "paragraf",
  IMEMO = "imemo",
  SIP = "sip",
}

export interface Source {
  id: number;
  name: string;
  comment: string;
  isActive: boolean;
  nameSpaceId: number;
  sourceDetail: SourceDetail;
  ownerId: number;
  dateChanged: string;
  dateCreated: string;
  status: string;
  message: string;
  isCanRead: boolean;
  isCanWrite: boolean;
}

// доступные технологии
export interface AvailableSource {
  id: number;
  name: string;
  comment: string;
  isCanChangeOnCreate: boolean;
  detail: {
    type: SourceType;
  };
}

// технологии в админке
export interface SourceAdmin {
  id: number;
  name: string;
  comment: string;
  isActive: boolean;
  isCanChangeOnCreate: boolean;
  sourceDetail: SourceDetail;
  isCanDelete: boolean;
  ownerId: number;
  dateChanged: string;
  dateCreated: string;
}
export enum FolderType {
  SFTP = "sftp",
  SMB = "smb",
}
export interface FolderSftp {
  type: FolderType.SFTP;
  host: string;
  login: string;
  password: string;
  path: string;
}

export const defaultFolderSftp: FolderSftp = {
  type: FolderType.SFTP,
  host: "127.0.0.1",
  login: "replica",
  password: "",
  path: "/mnt/storage",
};

export interface FolderSmb {
  type: FolderType.SMB;
  smbVersion: SmbVersion;
  smbPath: string;
  login: string;
  password: string;
}

export const defaultFolderSmb: FolderSmb = {
  type: FolderType.SMB,
  smbVersion: "2.1",
  smbPath: "\\\\127.0.0.1\\shared\\folder",
  login: "replica",
  password: "",
};

// Shared folder
export interface SourceDetailSharedFolder {
  type: SourceType.SHARED_FOLDER;
  folderDetail: FolderSftp | FolderSmb;
  deleteFilter: string;
  sleepSec: number;
  isEchFolderInNewNamespace: boolean;
  storageId: number;
  fileFormatDetail: FileFormatDetail;
}

export const defaultSourceDetailSharedFolder: SourceDetailSharedFolder = {
  type: SourceType.SHARED_FOLDER,
  folderDetail: defaultFolderSftp,
  deleteFilter: "",
  sleepSec: 60,
  isEchFolderInNewNamespace: false,
  storageId: 0,
  fileFormatDetail: defaultFormatFile,
};

// Sprut Postgres
export interface SourceDetailSprutPostgres {
  type: SourceType.SPRUT_POSTGRES;
  host: string;
  login: string;
  password: string;
  database: string;
  schema: string;
  sort: Sort;
  isSaveToTable: boolean;
  isClearComment: boolean;
  isSaveToComment: boolean;
  filter: string;
  deleteFilter: string;
  sleepSec: number;
}

export const defaultSourceDetailSprutPostgres: SourceDetailSprutPostgres = {
  type: SourceType.SPRUT_POSTGRES,
  host: "",
  login: "",
  password: "",
  database: "",
  schema: "",
  sort: "asc",
  isSaveToTable: false,
  isClearComment: false,
  isSaveToComment: false,
  filter: "",
  deleteFilter: "",
  sleepSec: 60,
};

// NppNttSprutPostgres
export interface SourceDetailNppNttSprutPostgres {
  type: SourceType.NPP_NTT_SPRUT_POSTGRES;
  host: string;
  login: string;
  password: string;
  database: string;
  schema: string;
  sort: Sort;
  filter: string;
  deleteFilter: string;
  sleepSec: number;
  isClearComment: boolean;
  isSaveToComment: boolean;
}

export const defaultSourceDetailNppNttSprutPostgres: SourceDetailNppNttSprutPostgres = {
  type: SourceType.NPP_NTT_SPRUT_POSTGRES,
  host: "",
  login: "",
  password: "",
  database: "",
  schema: "",
  sort: "asc",
  filter: "",
  deleteFilter: "",
  sleepSec: 60,
  isClearComment: false,
  isSaveToComment: false,
};

// Sprut Interbase
export interface SourceDetailSprutInterbase {
  type: SourceType.SPRUT_INTERBASE;
  host: string;
  login: string;
  password: string;
  database: string;
  sort: Sort;
  filter: string;
  deleteFilter: string;
  sleepSec: number;
  isClearComment: boolean;
  isSaveToComment: boolean;
}

export const defaultSourceDetailSprutInterbase: SourceDetailSprutInterbase = {
  type: SourceType.SPRUT_INTERBASE,
  host: "",
  login: "",
  password: "",
  database: "",
  sort: "asc",
  filter: "",
  deleteFilter: "",
  sleepSec: 60,
  isClearComment: false,
  isSaveToComment: false,
};

// Sprut Oracle
export interface SourceDetailSprutOracle {
  type: SourceType.SPRUT_ORACLE;
  host: string;
  login: string;
  password: string;
  database: string;
  schema: string;
  sort: Sort;
  filter: string;
  deleteFilter: string;
  sleepSec: number;
  isClearComment: boolean;
  isSaveToComment: boolean;
}

export const defaultSourceDetailSprutOracle: SourceDetailSprutOracle = {
  type: SourceType.SPRUT_ORACLE,
  host: "",
  login: "",
  password: "",
  database: "",
  schema: "",
  sort: "asc",
  filter: "",
  deleteFilter: "",
  sleepSec: 60,
  isClearComment: false,
  isSaveToComment: false,
};

// Virtus Postgres
export interface SourceDetailVirtusPostgres {
  type: SourceType.VIRTUS_POSTGRES;
  host: string;
  login: string;
  password: string;
  database: string;
  schema: string;
  sort: Sort;
  filter: string;
  deleteFilter: string;
  sleepSec: number;
  isSaveToTable: boolean;
}

export const defaultSourceDetailVirtusPostgres: SourceDetailVirtusPostgres = {
  type: SourceType.VIRTUS_POSTGRES,
  host: "",
  login: "",
  password: "",
  database: "",
  schema: "",
  sort: "asc",
  deleteFilter: "",
  filter: "",
  sleepSec: 60,
  isSaveToTable: true,
};

// Параграф
export interface SourceDetailParagraf {
  type: SourceType.PARAGRAF;
  id: string;
  machineId: string;
  sort: Sort;
  isSaveResultBackToParagraf: boolean;
  isSaveFile: boolean;
  deleteFilter: string;
  sleepSec: number;
}

export const defaultSourceDetailParagraf: SourceDetailParagraf = {
  type: SourceType.PARAGRAF,
  id: "",
  machineId: "",
  sort: "asc",
  isSaveResultBackToParagraf: false,
  isSaveFile: false,
  deleteFilter: "",
  sleepSec: 60,
};

// Imemo
export interface SourceDetailImemo {
  type: SourceType.IMEMO;
  folderDetail: FolderSftp | FolderSmb;
  deleteFilter: string;
  sleepSec: number;
}

export const defaultSourceDetailImemo: SourceDetailImemo = {
  type: SourceType.IMEMO,
  folderDetail: defaultFolderSftp,
  deleteFilter: "",
  sleepSec: 60,
};

// Sip
export interface SourceDetailSip {
  type: SourceType.SIP;
  folderDetail: FolderSftp | FolderSmb;
  deleteFilter: string;
  sleepSec: number;
  dateChangedSec: number;
}

export const defaultSourceDetailSip: SourceDetailSip = {
  type: SourceType.SIP,
  folderDetail: defaultFolderSftp,
  deleteFilter: "",
  sleepSec: 60,
  dateChangedSec: 0,
};

export type SourceDetail =
  | SourceDetailSharedFolder
  | SourceDetailSprutPostgres
  | SourceDetailNppNttSprutPostgres
  | SourceDetailSprutInterbase
  | SourceDetailSprutOracle
  | SourceDetailVirtusPostgres
  | SourceDetailParagraf
  | SourceDetailImemo
  | SourceDetailSip;

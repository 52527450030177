import React, { ChangeEvent, FC } from "react";
import { FolderSftp as FolderSftpType, FolderSmb as FolderSmbType, FolderType } from "types/source";
import FolderSftp from "./FolderSftp";
import FolderSmb from "./FolderSmb";

// material ui
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

interface Props {
  disabled?: boolean;
  folderDetail: FolderSmbType | FolderSftpType;
  onChangeConnectionType: (e: ChangeEvent<{ value: unknown }>) => void;
  onChangeSmbVersion: (e: ChangeEvent<{ value: unknown }>) => void;
  onChangeInputElement: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setPathValue: (value: string, pathName?: string) => void;
}

const SelectProtocol: FC<Props> = ({
  folderDetail,
  onChangeConnectionType,
  onChangeInputElement,
  setPathValue,
  onChangeSmbVersion,
  disabled,
}) => {
  const { type } = folderDetail;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth disabled={!!disabled}>
          <InputLabel id="select-folder-label">Протокол</InputLabel>
          <Select labelId="select-folder-label" value={type} onChange={onChangeConnectionType} fullWidth>
            <MenuItem value={FolderType.SFTP}>SFTP</MenuItem>

            <MenuItem value={FolderType.SMB}>SMB</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        {type === FolderType.SFTP && (
          <FolderSftp
            state={folderDetail as FolderSftpType}
            onChange={onChangeInputElement}
            setPathValue={setPathValue}
          />
        )}
        {type === FolderType.SMB && (
          <FolderSmb
            state={folderDetail as FolderSmbType}
            onChange={onChangeInputElement}
            changeSmbVersion={onChangeSmbVersion}
            setPathValue={setPathValue}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SelectProtocol;

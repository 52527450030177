import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { DownloadTextType, Record, RecordDetail, RecordFileViewType, Segment as SegmentType } from "types/record";
import { Group } from "types/queryBuilder";
import { TechType, TaskDetailType, Task } from "types/task";
import useSegments from "hooks/records/useSegments";
import useTranslatedSegments from "hooks/records/useTranslateSegments";
import useKeywords from "hooks/records/useKeywords";
import {
  setSttSyncTextAndPlayer,
  setSttEditeMode,
  // setSttShowTranslate,
} from "redux/actions/settingsActions";
import TaskService from "services/TaskService";
import RecordService from "services/RecordService";
import EsbService from "services/EsbService";
import { showSuccessAlert } from "redux/actions/alertActions";
import { copyToClipboard, catchError } from "functions/common";
import { checkSourceParagraf } from "functions/sources";
import { subscriber } from "subscribers/RecordStatusSubscriber";

// components
import Player from "./Player";
import Flag from "components/Flag";
import Segments from "./Segments";
import Keywords from "./Keywords";
import STTSettings from "./STTSettings";
import CreateTaskSttDialog from "./CreateTaskSttDialog";
import DiarizationSettings from "./DiarizationSettings";
import CopyToClipboard from "./CopyToClipboard";
import ConfidenceHighlight from "./ConfidenceHighlight";
import SendToParagrafDialog from "./SendToParagrafDialog";
import ParagrafIcon from "components/icons/ParagrafIcon";

// icons
import TextFieldsIcon from "@material-ui/icons/TextFields";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import OpenEditorButton from "./OpenEditorButton";
import CollapseButtons from "./CollapseButtons";
import ReplicaEditorService from "services/ReplicaEditorService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 231px)",
  },
  player: {
    padding: theme.spacing(0, 1),
  },
  stt: {
    flexGrow: 1,
    overflow: "auto",
    padding: theme.spacing(0, 1),
    display: "flex",
    justifyContent: "space-between",
  },
  keywords: {
    flexBasis: 300,
    flexGrow: 0,
    flexShrink: 0,
  },
  buttons: {
    display: "flex",
    padding: "0px 0 0px 8px",
    alignItems: "center",
    margin: theme.spacing(1, 0),
    marginBottom: "0px",
  },
  translate: {
    marginRight: 10,
    width: 100,
  },
  flagButton: {
    marginRight: 10,
    padding: "0px",
    height: "fit-content",
  },
  fc: {
    height: "fit-content",
  },
  ml10: {
    marginLeft: 10,
  },
  ml20: {
    marginLeft: 20,
  },
  mr10: {
    marginRight: 10,
  },
  mr30: {
    marginRight: 30,
  },
  settings: {
    display: "flex",
    alignItems: "center",
  },
  list: {
    width: 100,
  },
  body: {
    width: 315,
    padding: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
  segments: {
    height: "100%",
  },
  flex50: {
    flexBasis: "50%",
  },
  divider: {
    margin: "0 13px",
  },
  hc: {
    width: 200,
    marginRight: 30,
  },
  actions: {
    flexGrow: 1,
    textAlign: "left",
  },
  confidenceWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "16px",
  },
}));

interface Props {
  queryFilter: string;
  viewType: RecordFileViewType.AUDIO;
  record: Record;
  filterv2: string; // поисковая строка
  recordDetail: RecordDetail;
  setProgress: Dispatch<SetStateAction<boolean>>;
}

const STT: FC<Props> = ({ queryFilter, viewType, record, filterv2, recordDetail, setProgress }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { settings, system } = useSelector((state: RootState) => state);

  const {
    playerChannel,
    sttSyncTextAndPlayer,
    sttShowKeywords,
    sttShowTranslate,
    sttBackgroundColor,
    diarizationHighlight,
    sttFont,
    sttFontSize,
    sttTextColor,
    editMode,
  } = settings;

  const [anchorSettings, setAnchorSettings] = useState<null | HTMLElement>(null);
  const [anchorCopyToClipboard, setAnchorCopyToClipboard] = useState<null | HTMLElement>(null);
  const [anchorDownloadFile, setAnchorDownloadFile] = useState<null | HTMLElement>(null);
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [diarizationSpeakers, setDiarizationSpeakers] = useState<string[]>([]);
  const [openSendToParagrafDialog, setOpenSendToParagrafDialog] = useState(false);

  // Параграф, если есть такой тип, не созданный, а вообще, если в системе есть такой источник
  // появится кнопка "отправить в параграф"
  const hasSourceParagraf = useMemo(() => checkSourceParagraf(system.sources), [system]);

  // query string для сегментов
  const segmentsQueryString = useMemo(() => {
    const highlight = diarizationHighlight ? 1 : 0;
    const speakers = diarizationSpeakers.join(",");
    const channel = record.channelCount === 1 ? -1 : playerChannel;
    return `?channel=${channel}&isHighlightDiarization=${highlight}&diarizationSpeakers=${speakers}`;
  }, [playerChannel, diarizationHighlight, diarizationSpeakers, record]);
  // сегменты
  const { segments, loading: loadingSegments, error, setState: setSegments } = useSegments(
    record.id,
    segmentsQueryString
  );
  // перевод
  const { segments: tSegments, request: translateSegmentsRequest } = useTranslatedSegments(
    record.id,
    segmentsQueryString
  );

  //ref for meadia

  const ref = useRef(null);

  const [audioUrl, setAudioUrl] = useState("");
  const [databaseText, setDatabaseText] = useState("");

  // query string для ключевых слов
  const keywordsQueryString = useMemo(() => `?filterv2=${filterv2}&channel=${playerChannel}`, [
    playerChannel,
    filterv2,
  ]);
  // ключевые слова
  const { keywords, loading: loadingKeywords, error: errorKeywords } = useKeywords(record.id, keywordsQueryString);

  const segmentsStyle = useMemo(
    () => ({
      flexGrow: 1,
      display: "flex",
      justifyContent: sttShowTranslate ? "" : "center",
      backgroundColor: sttBackgroundColor,
      fontFamily: sttFont,
      fontSize: sttFontSize,
      color: sttTextColor,
    }),
    [sttBackgroundColor, sttShowTranslate, sttFont, sttFontSize, sttTextColor]
  );

  const handleCloseCreateTaskDialog = (data?: any) => {
    setOpenCreateTaskDialog(false);
    if (data === undefined) return;

    const { techSTT, techVad } = data;
    const { lang, translate, languageModelId } = techSTT;
    const { vadType } = techVad;
    const techs: any = [
      { type: TechType.STT, IsEnableWord2Num: true, lang, languageModelId },
      { type: TechType.VAD, vadType },
    ];
    if (translate) {
      techs.push({ type: TechType.TRANSLATE });
    }
    const filter: Group = {
      type: "group",
      uuid: "0",
      operation: "AND",
      filters: [
        {
          uuid: "1",
          type: "filter",
          filter: "Идентификатор",
          condition: "=",
          value: String(record.id),
        },
      ],
    };
    const body: any = {
      name: "Автоматическое задание",
      comment: "",
      isActive: true,
      taskDetail: {
        type: TaskDetailType.Process,
        filterDetail: JSON.stringify(filter),
        techDetail: {
          isForce: true,
          techs,
        },
      },
    };
    TaskService.create(body)
      .then(() => {
        dispatch(showSuccessAlert("Задание создано и поставлено в очередь обработки."));
      })
      .catch(catchError);
  };

  const createAutoTranslateTask = () => {
    const filter: Group = {
      type: "group",
      uuid: "0",
      operation: "AND",
      filters: [
        {
          uuid: "1",
          type: "filter",
          filter: "Идентификатор",
          condition: "=",
          value: String(record.id),
        },
      ],
    };
    const body: Task = {
      id: 0,
      name: "Автоматическое задание на перевод",
      comment: "",
      isActive: true,
      taskDetail: {
        type: TaskDetailType.Process,
        filterDetail: JSON.stringify(filter),
        exports: [],
        techDetail: {
          isForce: true,
          techs: [{ type: TechType.TRANSLATE, id: "", lang: "" }],
        },
      },
      ownerId: 0,
      dateChanged: "",
      dateCreated: "",
      status: "starting",
      message: "",
      taskInProcessed: 0,
      taskWeight: 0,
      isCanRead: false,
      isCanWrite: false,
    };

    TaskService.create(body)
      .then(() => {
        // dispatch(showSuccessAlert("Задание на перевод создано и поставлено в очередь обработки."));
      })
      .catch(catchError);
  };

  const copyResultToClipboard = useCallback(
    (type: DownloadTextType) => {
      RecordService.getSTTResultAsText(record.id, type)
        .then(({ data }) => {
          if (copyToClipboard(data)) {
            dispatch(showSuccessAlert("Скопировано в буфер обмена."));
            setAnchorCopyToClipboard(null);
          }
        })
        .catch(catchError);
    },
    [record, dispatch]
  );

  const downloadSttText = useCallback(
    (type: DownloadTextType) => {
      RecordService.getSTTResultAsFile(record.id, type)
        .then(({ data }) => {
          const href = window.URL.createObjectURL(data);
          const a = document.createElement("a");
          const noExtension: string = record.name.slice(0, record.name.lastIndexOf("."));
          a.href = href;
          //так как в рекорд нэйм есть расширение, то нужно его обрезать до точки + добавить тхт
          a.download = noExtension + ".txt";
          a.click();
          a.remove();
          URL.revokeObjectURL(href);
        })
        .catch(catchError);
    },
    [record]
  );

  // обработчик нажатия клавиш
  const handleKeyDown = useCallback(
    (event: any) => {
      if (!editMode) return;
      event.stopPropagation();
      const { key } = event;
      if (key === "Enter") event.preventDefault();
    },
    [editMode]
  );

  const updateSegment = (segment: SegmentType) => {
    setSegments((prev) => {
      const { segments } = prev;
      const updatedSegments = segments.map((s) => (s.id === segment.id ? segment : s));
      return { ...prev, segments: updatedSegments };
    });
  };

  // отправить нарушение связи в Параграф
  const handleCloseSendToParagrafDialog = (data?: any) => {
    setOpenSendToParagrafDialog(false);
    if (!data) return;

    const body = { recordId: record.id, ...data };
    EsbService.sendDocumentNbs(body)
      .then(() => {
        dispatch(showSuccessAlert("Записи успешно отправлены"));
      })
      .catch(catchError);
  };

  const handleChangeEditMode = () => {
    dispatch(setSttEditeMode(!editMode));
    if (editMode) {
      createAutoTranslateTask();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (errorKeywords === undefined) return;
    catchError(errorKeywords);
  }, [errorKeywords]);

  useEffect(() => {
    subscriber.subscribe(({ data }) => {
      if (data === undefined) return;

      // если изменилась выбранная запись
      if (record.id === data.id) {
        setProgress(data.status === "progress");
        if (data.status === "ok") translateSegmentsRequest();
      }
    });
  }, [record, translateSegmentsRequest, setProgress]);

  useEffect(() => {
    ReplicaEditorService.getAll(record.id)
      .then((res: any) => {
        setDatabaseText(res.data);
      })
      .catch(catchError);
  }, [record.id]);

  return (
    <div className={classes.root}>
      <div className={classes.player}>
        <Player
          queryFilter={queryFilter}
          viewType={viewType}
          segments={segments}
          keywords={keywords}
          fileName={record.name}
          recordChannel={record.channelCount}
          setAudioUrl={setAudioUrl}
        />
      </div>
      <div ref={ref} className={classes.buttons}>
        <div className={classes.actions}>
          <Button
            className={classes.flagButton}
            color="primary"
            size="small"
            variant="contained"
            onClick={() => setOpenCreateTaskDialog(true)}
          >
            <Flag lang={record.lang} />
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            className={clsx(classes.mr10, classes.fc)}
            title="Сохранить текст в буфер обмена"
            onClick={(e) => setAnchorCopyToClipboard(e.currentTarget)}
          >
            <FileCopyIcon />
          </Button>
          <Button
            className={clsx(classes.mr10, classes.fc)}
            color="primary"
            size="small"
            variant="contained"
            onClick={(e) => setAnchorDownloadFile(e.currentTarget)}
            title="Сохранить текст в файл"
          >
            <GetAppIcon />
          </Button>
          {record.isDiarization && (
            <DiarizationSettings
              recordId={record.id}
              diarizationSpeakers={diarizationSpeakers}
              setDiarizationSpeakers={setDiarizationSpeakers}
            />
          )}
          {hasSourceParagraf && (
            <Button
              className={clsx(classes.mr10, classes.fc)}
              color="primary"
              variant="contained"
              size="small"
              title="Отправить в Параграф"
              onClick={() => setOpenSendToParagrafDialog(true)}
            >
              <ParagrafIcon />
            </Button>
          )}
          <Button
            className={clsx(classes.mr10, classes.fc)}
            color="primary"
            variant="contained"
            size="small"
            title="Настройки отображения текста"
            onClick={(e) => setAnchorSettings(e.currentTarget)}
          >
            <TextFieldsIcon />
          </Button>
          <OpenEditorButton
            recordId={record.id}
            databaseText={databaseText}
            segments={segments}
            audioUrl={audioUrl}
            />  
        </div>
        <div className={classes.settings}>
          <CollapseButtons
            handleChangeEditMode={handleChangeEditMode}
            sttShowKeywords={sttShowKeywords}
            sttShowTranslate={sttShowTranslate}
            setSttSyncTextAndPlayer={setSttSyncTextAndPlayer}
            sttSyncTextAndPlayer={sttSyncTextAndPlayer}
            editMode={editMode}
          />
        </div>
      </div>
      {editMode && (
        <div className={classes.confidenceWrapper}>
          <div className={classes.hc}>
            <ConfidenceHighlight />
          </div>
        </div>
      )}

      <div className={classes.stt}>
        <div style={segmentsStyle} className="app_stt-segments">
          <Segments
            segments={segments}
            tSegments={tSegments}
            loading={loadingSegments}
            error={error}
            keywords={keywords}
            alwaysOnDisplay
            showTranslate={sttShowTranslate}
            recordDetail={recordDetail}
            updateSegment={updateSegment}
          />
        </div>

        <div className={clsx(classes.keywords, { [classes.hidden]: !sttShowKeywords })}>
          <Keywords keywords={keywords} loading={loadingKeywords} />
        </div>
      </div>

      <STTSettings anchorEl={anchorSettings} setAnchorEl={setAnchorSettings} />
      <CopyToClipboard
        anchorEl={anchorCopyToClipboard}
        setAnchorEl={setAnchorCopyToClipboard}
        onClick={copyResultToClipboard}
      />

      <CopyToClipboard anchorEl={anchorDownloadFile} setAnchorEl={setAnchorDownloadFile} onClick={downloadSttText} />

      <CreateTaskSttDialog open={openCreateTaskDialog} onClose={handleCloseCreateTaskDialog} sttLang={record.lang} />

      <SendToParagrafDialog open={openSendToParagrafDialog} onClose={handleCloseSendToParagrafDialog} />
    </div>
  );
};

export default STT;

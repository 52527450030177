import React, { ChangeEvent, FC, useMemo, useState } from "react";
import {
  AvailableSource,
  defaultSourceDetailImemo,
  defaultSourceDetailNppNttSprutPostgres,
  defaultSourceDetailParagraf,
  defaultSourceDetailSharedFolder,
  defaultSourceDetailSip,
  defaultSourceDetailSprutInterbase,
  defaultSourceDetailSprutOracle,
  defaultSourceDetailSprutPostgres,
  defaultSourceDetailVirtusPostgres,
  Source,
  SourceDetail as SourceDetailType,
  SourceType,
} from "types/source";
import useAvailableSources from "hooks/admin/useAvailableSources";
import { getDefaultSource } from "functions/sources";

// components
import SelectNamespace from "components/SelectNamespace";
import SourceDetail from "./SourceDetail";
import NoData from "components/NoData";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Buttons from "./Buttons";
import DialogActions from "@material-ui/core/DialogActions";
import Alert from "@material-ui/lab/Alert";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import InboxIcon from "@material-ui/icons/Inbox";
import StorageIcon from "@material-ui/icons/Storage";
import FolderIcon from "@material-ui/icons/Folder";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import DialerSipIcon from "@material-ui/icons/DialerSip";
import PhoneIcon from "@material-ui/icons/Phone";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(() => ({
  list: {
    width: 400,
    margin: "0 auto",
  },
}));

interface PropsCreateUpdateDialog {
  onClose: (data?: Source) => void;
  source: Source;
  availableSources: AvailableSource[];
  create: boolean;
}
const CreateUpdateDialog: FC<PropsCreateUpdateDialog> = ({ source, onClose, availableSources, create }) => {
  const [state, setState] = useState<Source>(source);
  const { name, comment, isActive, nameSpaceId, sourceDetail } = state;

  const title = create ? "Создать источник" : "Редактировать источник";
  // блокировать элементы при редактировании
  const disableElement = useMemo(() => !create, [create]);
  // блокировать кнопку "Сохранить"
  const disableSave = useMemo(() => name.length === 0, [name]);

  const handleChangeType = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;

    let sourceDetail: SourceDetailType;
    if (value === SourceType.SHARED_FOLDER) {
      sourceDetail = defaultSourceDetailSharedFolder;
    }
    if (value === SourceType.PARAGRAF) {
      sourceDetail = defaultSourceDetailParagraf;
    }
    if (value === SourceType.SPRUT_POSTGRES) {
      sourceDetail = defaultSourceDetailSprutPostgres;
    }
    if (value === SourceType.SPRUT_INTERBASE) {
      sourceDetail = defaultSourceDetailSprutInterbase;
    }
    if (value === SourceType.SPRUT_ORACLE) {
      sourceDetail = defaultSourceDetailSprutOracle;
    }
    if (value === SourceType.NPP_NTT_SPRUT_POSTGRES) {
      sourceDetail = defaultSourceDetailNppNttSprutPostgres;
    }
    if (value === SourceType.VIRTUS_POSTGRES) {
      sourceDetail = defaultSourceDetailVirtusPostgres;
    }
    if (value === SourceType.IMEMO) {
      sourceDetail = defaultSourceDetailImemo;
    }
    if (value === SourceType.SIP) {
      sourceDetail = defaultSourceDetailSip;
    }

    setState((prev) => ({
      ...prev,
      sourceDetail: sourceDetail,
    }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setState((prev) => ({ ...prev, [name]: checked }));
  };

  const setNamespaceId = (id: number) => {
    setState((prev: Source) => ({
      ...prev,
      nameSpaceId: id,
    }));
  };

  const setSourceDetail = (sourceDetail: SourceDetailType) => {
    setState((prev: Source) => ({
      ...prev,
      sourceDetail: sourceDetail,
    }));
  };

  return (
    <Dialog onClose={() => onClose()} open={true} fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Имя"
              fullWidth
              variant="standard"
              name="name"
              onChange={handleChange}
              value={name}
              autoFocus={create}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              variant="standard"
              name="comment"
              onChange={handleChange}
              value={comment}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectNamespace disabled={!create} namespaceId={nameSpaceId} setNamespaceId={setNamespaceId} />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isActive} onChange={handleChecked} name="isActive" color="primary" />}
              label="Активен"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth disabled={disableElement}>
              <InputLabel id="select-type-label">Тип</InputLabel>
              <Select labelId="select-type-label" value={state.sourceDetail.type} onChange={handleChangeType} fullWidth>
                {availableSources.length === 0 ? (
                  <NoData />
                ) : (
                  availableSources.map((s) => (
                    <MenuItem key={s.id} value={s.detail.type}>
                      {s.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <SourceDetail state={sourceDetail} setState={setSourceDetail} disable={disableElement} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Buttons onClose={onClose} handleSave={() => onClose(state)} disabled={disableSave} />
      </DialogActions>
    </Dialog>
  );
};

interface PropsPrepareStage3 {
  onClose: (data?: Source) => void;
  source?: Source;
  availableSources: AvailableSource[];
}

const PrepareStage3: FC<PropsPrepareStage3> = ({ onClose, source, availableSources }) => {
  const classes = useStyles();
  const [selectedSource, setSelectedSource] = useState<Source | undefined>(undefined);

  const onClick = (s: AvailableSource) => {
    const source = getDefaultSource(s.detail.type);
    source.name = s.name;
    setSelectedSource(source);
  };

  const Icon: FC<{ sourceType: SourceType }> = ({ sourceType }) => {
    const db = [
      SourceType.SPRUT_POSTGRES,
      SourceType.VIRTUS_POSTGRES,
      SourceType.NPP_NTT_SPRUT_POSTGRES,
      SourceType.SPRUT_ORACLE,
      SourceType.SPRUT_INTERBASE,
    ];

    if (sourceType === SourceType.SHARED_FOLDER) return <FolderIcon />;
    if (sourceType === SourceType.PARAGRAF) return <LocalParkingIcon />;
    if (sourceType === SourceType.IMEMO) return <PhoneIcon />;
    if (sourceType === SourceType.SIP) return <DialerSipIcon />;
    if (db.includes(sourceType)) return <StorageIcon />;

    return <InboxIcon />;
  };

  // Обновление
  if (source !== undefined) {
    return <CreateUpdateDialog onClose={onClose} source={source} availableSources={availableSources} create={false} />;
  }

  // Пользователь выбрал источник
  if (selectedSource !== undefined) {
    return (
      <CreateUpdateDialog onClose={onClose} source={selectedSource} availableSources={availableSources} create={true} />
    );
  }

  return (
    <Dialog onClose={() => onClose()} open={true} fullWidth maxWidth="md">
      <DialogTitle>Создать источник</DialogTitle>
      <DialogContent>
        <List component="nav" className={classes.list}>
          {availableSources.map((s) => (
            <ListItem button key={s.id} onClick={() => onClick(s)}>
              <ListItemIcon>
                <Avatar>
                  <Icon sourceType={s.detail.type} />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={s.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Buttons onClose={onClose} handleSave={() => onClose()} disabled />
      </DialogActions>
    </Dialog>
  );
};

interface PropsPrepareStage2 {
  onClose: (data?: Source) => void;
  source?: Source;
}
const PrepareStage2: FC<PropsPrepareStage2> = ({ onClose, source }) => {
  const { availableSources, loading, error } = useAvailableSources();

  // Если загрузка, то ничего не показывать, возможно это изменть
  if (loading) return null;

  if (error !== undefined) {
    return (
      <Dialog onClose={() => onClose()} open={true} fullWidth maxWidth="md">
        <DialogTitle>Создать источник</DialogTitle>
        <DialogContent>
          <Alert severity="error">{error?.response?.data?.message ?? "Неизвестная ошибка"}</Alert>
        </DialogContent>
        <DialogActions>
          <Buttons onClose={onClose} handleSave={() => onClose()} disabled={false} />
        </DialogActions>
      </Dialog>
    );
  }

  if (availableSources.length === 0)
    return (
      <Dialog onClose={() => onClose()} open={true} fullWidth maxWidth="md">
        <DialogTitle>Создать источник</DialogTitle>
        <DialogContent>
          <Alert severity="error">Нет доступных источников</Alert>
        </DialogContent>
        <DialogActions>
          <Buttons onClose={onClose} handleSave={() => onClose()} disabled={false} />
        </DialogActions>
      </Dialog>
    );

  return <PrepareStage3 onClose={onClose} availableSources={availableSources} source={source} />;
};

interface PropsPrepareStage1 {
  open: boolean;
  onClose: (data?: Source) => void;
  source?: Source;
}
const PrepareStage1: FC<PropsPrepareStage1> = ({ open, onClose, source }) =>
  open ? <PrepareStage2 onClose={onClose} source={source} /> : null;

export default PrepareStage1;

import React, { ChangeEvent, FC, MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setSttShowKeywords,
  setSttShowTranslate,
  // setSttSyncTextAndPlayer,
  // setSttEditeMode,
} from "redux/actions/settingsActions";

// icons
import MoreVertIcon from "@material-ui/icons/MoreVert";

// material ui
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { grey, orange } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    settings: {
      width: 400,
      padding: theme.spacing(2),
      margin: 0,
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
    button: {},
    popover: {
      overflow: "hidden",
    },
    colorGrey: {
      color: grey[600],
    },
    diff: {
      width: 3,
      height: 15,
      backgroundColor: orange[600],
      marginRight: 10,
      marginLeft: -13,
    },
    item: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    container: {
      display: "flex",
      alignItems: "center",
    },
    hidden: {
      display: "none",
    },
  })
);

interface Props {
  handleChangeEditMode: () => void;
  sttShowKeywords: boolean;
  sttShowTranslate: boolean;
  setSttSyncTextAndPlayer: Function;
  editMode: boolean;
  sttSyncTextAndPlayer: boolean;
}

const CollapseButtons: FC<Props> = ({
  handleChangeEditMode,
  sttShowKeywords,
  sttShowTranslate,
  setSttSyncTextAndPlayer,
  editMode,
  sttSyncTextAndPlayer,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.currentTarget.blur();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    // const payload = checked ? 1 : 0;
    if (name === "isShowKeywords") {
      dispatch(setSttShowKeywords(!sttShowKeywords));
    }
    if (name === "isShowTranslate") {
      dispatch(setSttShowTranslate(!sttShowTranslate));
    }
    if (name === "isSync") {
      dispatch(setSttSyncTextAndPlayer(!sttSyncTextAndPlayer));
    }
  };

  return (
    <div>
      <IconButton className={classes.button} onClick={handleClick} title="Настройки" disableRipple>
        <Badge max={1000}>
          <MoreVertIcon />
        </Badge>
      </IconButton>

      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Grid container spacing={2} className={classes.settings}>
          <Grid item xs={12}>
            <div className={classes.container}>
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sttShowKeywords}
                      onChange={handleCheckbox}
                      color="primary"
                      name="isShowKeywords"
                    />
                  }
                  label="Показать ключевые слова"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.container}>
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sttShowTranslate}
                      onChange={handleCheckbox}
                      color="primary"
                      name="isShowTranslate"
                    />
                  }
                  label="Показать перевод"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.container}>
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox checked={sttSyncTextAndPlayer} onChange={handleCheckbox} color="primary" name="isSync" />
                  }
                  label="Синхронизировать текст и аудио"
                />
              </div>
            </div>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <div className={classes.container}>
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editMode}
                      onChange={handleChangeEditMode}
                      color="primary"
                      name="isShowLayoutVad"
                    />
                  }
                  label="Режим редактирования"
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

export default CollapseButtons;
